import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';
import { toCurrency } from 'bv-helpers/number';

/* eslint react/destructuring-assignment:off */
const StakeLimitsBreachModal = (props) => {
  const {
    title,
    subtitle,
    onEditBtnClick,
    onCloseBtnClick,
    translationsNamespace: tNamespace,
    fetchLimitsEndpoint,
    fetchLimitConfigs,
  } = props;

  const [isOpened, setIsOpened] = useState(false);
  const [limits, setLimits] = useState(props.limits);

  const [limitCoolOffPeriod, setLimitCoolOffPeriod] = useState('');

  useEffect(() => {
    if (!limits.length) {
      fetchLimitsEndpoint().then((data) => {
        setLimits(data.filter((limit) => limit.remaining === 0 && limit.period));
        setIsOpened(true);
      });
    } else {
      setIsOpened(true);
    }
  }, []);

  useEffect(() => {
    if (tNamespace === 'loss_limits') {
      fetchLimitConfigs().then((configs) => {
        if (configs.length > 0) {
          setLimitCoolOffPeriod(configs[0].coolOffPeriod);
        }
      });
    }
  }, []);

  if (!isOpened) return null;

  return (
    <Modal
      title={typeof title === 'function' ? title() : title}
      danger
      icon
      actions={[
        {
          id: 'ok-btn',
          label: t('edit'),
          inverse: true,
          remove: true,
          danger: true,
          onClick: () => {
            setIsOpened(false);
            onEditBtnClick();
          },
          className: 'stake-limits-breach__edit-button',
        },
        {
          id: 'no-btn',
          label: t(`${tNamespace}.breach_modal.close_button`),
          danger: true,
          close: true,
          onClick: () => {
            setIsOpened(false);
            onCloseBtnClick();
          },
        },
      ]}
    >
      <div className="stake-limits-breach">
        <p>{typeof subtitle === 'function' ? subtitle() : subtitle}</p>
        <hr />
        {limits.map((limit) => (
          <div className="stake-limits-breach__item" key={`${limit.type}_${limit.period}`}>
            <div className="stake-limits-breach__row">
              <p>{limit.typeLabel}</p>
              <p className="stake-limits-breach__amount">
                {toCurrency(limit.amount)}
                {' '}
                {limit.periodLabel}
              </p>
            </div>
            <div className="stake-limits-breach__row">
              <p>{t(`${tNamespace}.breach_modal.remaining_amount`)}</p>
              <p className="stake-limits-breach__amount">
                {toCurrency(limit.remaining)}
              </p>
            </div>
          </div>
        ))}
        {limitCoolOffPeriod && (
          <p>{t(`${tNamespace}.breach_modal.description`, { period: limitCoolOffPeriod })}</p>
        )}
      </div>
    </Modal>
  );
};

StakeLimitsBreachModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  limits: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    typeLabel: PropTypes.string.isRequired,
    period: PropTypes.string,
    periodLabel: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    remaining: PropTypes.number.isRequired,
  })),
  onEditBtnClick: PropTypes.func.isRequired,
  onCloseBtnClick: PropTypes.func.isRequired,
  translationsNamespace: PropTypes.string.isRequired,
  fetchLimitsEndpoint: PropTypes.func.isRequired,
  fetchLimitConfigs: PropTypes.func.isRequired,
};

StakeLimitsBreachModal.defaultProps = {
  limits: [],
};

export default StakeLimitsBreachModal;
