import StakeLimitsBreachModal from './components/stake_limits_breach_modal';
import { fetchStakeLimits } from './api';
import { fetchLimitConfigs } from '../stake_limits/api';

const StakeLimitsBreachModalApp = (props) => (
  <StakeLimitsBreachModal
    fetchLimitsEndpoint={fetchStakeLimits}
    fetchLimitConfigs={fetchLimitConfigs}
    {...props}
  />
);

export default StakeLimitsBreachModalApp;
